import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

export default function Contact(){
  const form = useRef();
  const [status,setStatus] = useState(""); 


  const {REACT_APP_SERVICE_ID,REACT_APP_CONTACT_TEMPLATE,REACT_APP_USER_ID} = process.env;

  // const serviceID = process.env.REACT_APP_SERVICE_ID;
  // const template = process.env.REACT_APP_CONTACT_TEMPLATE;
  // const userID = process.env.REACT_APP_USER_ID;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(`${REACT_APP_SERVICE_ID}`, `${REACT_APP_CONTACT_TEMPLATE}`, form.current, `${REACT_APP_USER_ID}` ).then((result) => {
      console.log(result.text);
      form.current.reset();
      setStatus("SUCCESS");
    }, (error) => {
      console.log(error.text);
    });
  };

   useEffect(() => {
      if(status === "SUCCESS"){
        setTimeout(() => {
          setStatus("");
        }, 7000);
      }
   }, [status])
  
    return (
        <div>
            <div id="contact-me">
      <h3 className="contact-header"> Contact Me </h3>
        <div className="contact-div">

          <div id="contact-info"> 
            <h4> Get In Touch.</h4>
            <p> Are you in search of some creative insight? Feel free to leave any comments, questions, or concerns you may have! </p>
            <p style={{textDecoration: "underline"}}> <b> comfycodez@gmail.com  </b> </p>
            <a href="https://www.instagram.com/comfycode/" target="_id"><FontAwesomeIcon icon={faInstagram}  style={{margin: "10px 20px 0px 0px"}} size="2x"/> </a>
            <a href="https://www.linkedin.com/in/keon-henton-b2727914a/" target="_id"><FontAwesomeIcon icon={faLinkedinIn}  style={{margin: "10px 0px 0px 0px"}} size="2x" /> </a>
          </div>


          <div id="contact-form">
           {status === "SUCCESS"? renderAlert(): null}
           {status === "FAILURE"? renderAlert2(): null}
            
            <form ref={form} onSubmit={sendEmail} style={{margin: "auto", width: "90%"}}>
            <div>
            <input className="inputs-line-1" type="text" name="firstName" placeholder="First Name" /> 
            <input className="inputs-line-1" type="text" name="lastName" placeholder="Last Name" />
            </div>

            <div> 
              <input className="inputs" type="email" name="user_email" placeholder="Email Address"/>
            </div>

            <div >
              <input className="inputs" type="text" name="user_subject" placeholder="Subject"></input>
            </div>

            <div>
              <textarea name="message" placeholder="The more elaborate your message the better " />
            </div>

          
              <input className="submit-btn" type="submit" value="Send"/>
             

          </form>
          </div>
        </div>
    </div>
        </div>
    )
}

const renderAlert = () => (
  <div className="email-alert-success">
      <p> Email has been sent succesfully.</p>
    </div>
)

const renderAlert2 = () => (
  <div className="email-alert-failure">
       <p> Something went wrong.Please try again.</p>
  </div> 
)