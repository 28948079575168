export default function Skills(){
   return (
     <div>
         <div id="skills">
                <h3 className="skills-header" > Skills </h3> 
                
                <div className="skills-div"> 
                
                  <img  alt="html" src="./html.png"/> 
                
                  <img  alt="css" src="./css.png" /> 
                
                  <img  alt="javascript" src="./javascript.png" /> 
                
                  <img  alt="react" src="./react.png" /> 
                
                  <img  alt="nodejs" src="./nodejs.png" /> 
                
                  <img  alt="github" src="./git.png" /> 
                
                  <img  alt="java" src="./java.png" /> 
                
                  <img  alt="firebase" src="./firebase.png" /> 
                
                </div>
        </div>
     </div>
   )
}