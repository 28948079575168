import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";


export default function About(){
    return (
        <div >
          
            <div id="header"> 
        <ul> 
          <a href="https://www.linkedin.com/in/keon-henton-b2727914a/" target="_id"> <FontAwesomeIcon icon={faLinkedinIn} style={{color: "#2541b2"}} size="2x" /> </a>
          <a href="https://github.com/comfy-code" target="_id"> <FontAwesomeIcon icon={faGithub}  style={{color: "#2541b2"}} size="2x"/>  </a>
          <a href="https://www.instagram.com/comfycode/" target="_id"> <FontAwesomeIcon icon={faInstagram}   style={{color: "#2541b2"}} size="2x"/> </a>
        </ul>
      <div>
        <h2> Hello, I am Keon </h2> <br/>
        <p> Front End Developer </p> <br/>
        <p> I am a passionate developer using web technologies to build efficient applications, with a focus on creating solutions for different niches. </p>
    
        <a className="button" href="#contact-me"> Contact Me <FontAwesomeIcon style={{margin: `0px 10px`}} icon={faPaperPlane} /> </a>
     </div>
     
     <img alt="header" src="./headshot.png"/>
    
    </div>

    <div id="about">
      <div style={{textAlign: "center"}}>
      <h3 className="about-header" > About Me </h3>
      {/* <p> Hello  </p> */}
      </div>

      <div className="about-content">
        <img alt="lifesyle" src="./me.jpg"/> 
        <div>
          <p> I am a freelance Web Developer with a focus on the Front End. I am currently pursuing a Bachelor's in Computer Science with a concentration in Web Development at the University of North Carolina at Charlotte. I enjoy learning new web technologies to assist in the creation of efficient digital solutions. I am currently seeking freelance and career opportunities.  </p>
          <a href="https://docs.google.com/document/d/1IDaIvQUvzNY_rWFCVtAdEaQjQdicDK8yL32esdjHMmk/export?format=pdf" target="_id" className="about-button"> Download Resume <FontAwesomeIcon style={{margin: `0px 10px`}} icon={faFileDownload} /> </a>
        </div>
      </div>

    </div>
        </div>
    )
}