export default function Projects(){
    return (
        
            <div id="Projects">
                <h3 className="project-header"> Projects </h3>
                <div id="projects">

                <div className="projects-div">
                        <h5 className="projects-headers"> <a href="https://www.incomingheat.com/" target="_blank" rel="noreferrer"> Incoming Heat </a> </h5>
                        <img alt="incoming heat website screenshot" className="projects-img"  src="./ih.png"/>
                        <a href="https://www.incomingheat.com/" target="_blank" rel="noreferrer"> <b> Live Site </b></a>
                        <p className="projects-description"> I created this website for a local sneaker reselling business as a platform to display and sell their inventory. </p>
                        <p className="projects-tools"> • HTML/CSS/Javascript</p>
                        <p className="projects-tools"> • Responsive</p>
                        <p className="projects-tools"> • React.js/Redux </p>
                        <p className="projects-tools"> • Firebase </p>
                        <p className="projects-tools"> • Node.js </p>
                    </div>


                    <div className="projects-div">
                        <h5 className="projects-headers"> <a href="https://sk8dice.netlify.app/" target="_blank" rel="noreferrer"> SK8DICE </a> </h5>
                        <img alt="skate dice website screenshot" className="projects-img"  src="./sk8dice.png"/>
                        <a href="https://sk8dice.netlify.app/" target="_blank" rel="noreferrer"> <b> Live Site </b></a>
                        <p className="projects-description"> As I enjoy skateboarding, I created this project for fun to practice Javascript. This game emulates the popular game H-O-R-S-E. </p>
                        <p className="projects-tools"> • HTML/CSS/Javascript</p>
                        <p className="projects-tools"> • Responsive</p>
                    </div>

                   <div className="projects-div">
                        <h5 className="projects-headers"> <a href="https://lawntech.netlify.app/" target="_blank" rel="noreferrer">  Lawn Tech </a> </h5>
                        <img alt="lawn tech website screenshot" className="projects-img"  src="./lawntech.png"/>
                        <a href="https://lawntech.netlify.app/" target="_blank" rel="noreferrer"> <b> Live Site </b></a>
                        <p className="projects-description"> I designed this website as a revamped version of a local landscaping business. </p>
                        <p className="projects-tools"> • HTML/CSS/Javascript</p>
                        <p className="projects-tools"> • Web Design</p>
                    </div> 

                    <div className="projects-div">
                        <h5 className="projects-headers"> <a href="https://www.llty.site/" target="_blank" rel="noreferrer"> LLTY </a> </h5>
                        <img alt="long live the youth website screenshot" className="projects-img"  src="./llty.png"/>
                        <a href="https://www.llty.site/" target="_blank" rel="noreferrer"> <b> Live Site </b></a>
                        <p className="projects-description"> I coded this ecommerce website for a local small business as a freelance project. </p>
                        <p className="projects-tools"> • HTML/CSS/Javascript</p>
                        <p className="projects-tools"> • Responsive</p>
                        <p className="projects-tools"> • React</p>
                        <p className="projects-tools"> • Snipcart</p>
                    </div>

                    
                </div>
            </div>
       
    )
}