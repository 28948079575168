export default function Navbar(){
 return (
     <div>
         <nav className="nav" >
      <h1 >  Comfy Code  </h1> 
      <div className="nav-links">
       <a href="#contact-me"> Contact Me </a>
       <a href="https://docs.google.com/document/d/1pdkryWaEbX8OXclh0d5EmthiLE9XaF-J/edit?usp=sharing" target="_id"> Resume </a>
       <a href="#skills"> Skills </a>
       <a href="#Projects"> Projects</a>
       <a href="#about"> About </a>
      </div>
       
     </nav>
     </div>
 )
}